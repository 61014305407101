// ================================================================================================
//  File Name: app-invoice-print.scss
//  Description: Invoice Layout page layouts SCSS.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Hoy - Property Management System
//  Version: 1.0
//  Author: Jamhuriya Technology Solutions
//  Author URL: https://jtech.so
// ================================================================================================

@import "../bootstrap-extended/include"; // Bootstrap includes

html,
body {
  background: $white !important;
}

.invoice-print {
  min-width: 768px !important;
  font-size: 15px !important;

  .invoice-date-wrapper {
    display: flex;
    align-items: center;

    .invoice-date-title {
      width: 8rem;
    }
  }

  i,
  svg {
    fill: $body-color !important;
  }

  .invoice-total-wrapper {
    width: 100%;
    max-width: 12rem;
    .invoice-total-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .invoice-total-title {
        // width: 92px;
        margin-bottom: 0.35rem;
      }
      .invoice-total-amount {
        margin-bottom: 0.35rem;
        font-weight: 600;
      }
    }
  }
}

.invoice-print * {
  border-color: rgba($color: $black, $alpha: 0.5) !important;
  color: $body-color !important;
}

// style for print screen
@media print {
  html,
  body {
    width: 768px;
  }
  .invoice-print {
    padding: 0px !important;
  }
  .invoice-header {
    margin-bottom: 3rem;
    padding-right: 5rem;
    flex-direction: row !important;
    border-bottom: 1px solid $border-color;
  }

  .table-responsive {
    overflow: visible;

    table {
      table-layout: fixed;
      th.ps-4,
      td.ps-4 {
        width: 50%;
        padding-left: 0.5rem !important;
      }

      p {
        // word-wrap: break-word;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  hr {
    display: none;
  }
  .invoice-sales-total-wrapper {
    margin-bottom: 1rem;
  }
}
