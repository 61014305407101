// --- create app start----- //
.create-app-wizard {
  &.vertical {
    .avatar {
      &.avatar-tag {
        min-height: 48px;
        min-width: 48px;
        img {
          border-radius: 0;
        }
      }
    }
  }
}
// --- create app end----- //
